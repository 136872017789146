import React from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const RedirectTo: React.FC<RouteComponentProps & { to: string }> = ({ to }) => (
    <Redirect noThrow to={to} />
)

const focusInputWithPlaceholder = (placeholder: string) => {
    const input: HTMLInputElement | null = document.querySelector(
        `[placeholder="${placeholder}"]`
    )
    input && input.focus()
}

const validateEmail = (email: string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}
// Password policy: at least 8 chars long, uppercase and lowercase letter, number and a special character
const validatePassword = (password: string) => {
    const regex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&.*])[\w!@#$%^&.*]{8,}$/
    return regex.test(password)
}

const isDataConsistent = (data: any) =>
    data.emails && data.email && data.email.trim()

export {
    delay,
    RedirectTo,
    focusInputWithPlaceholder,
    validateEmail,
    validatePassword,
    isDataConsistent,
}
