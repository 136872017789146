import React, { useReducer, createContext, ReactNode } from 'react'
import { ApplicationState } from '../types'
import { delay } from '../utils'

const initialState: ApplicationState = {
    isLoggedIn: true,
    emails: [],
    tickets: [],
    keywords: [],
    isMessageShown: false,
    messageContent: '',
    isLoading: false,
    aPopupIsOpen: false, // used to remove hotjar feedback button when a popup is opened
    isGeneralFailure: false,
    isLoadingFailure: false,
    editEmailId: '',
    showAddEmailModal: false,
    showEditEmailModal: false,
    editKeywordId: '',
    showAddKeywordModal: false,
    showEditKeywordModal: false,
    emailId: '',
}

interface ContextProps {
    state: ApplicationState
    dispatch: (action: Action) => void
    showMessage: (text: string) => void
}

const initContextProps: ContextProps = {
    state: initialState,
    dispatch: () => true,
    showMessage: () => true,
}
const ApplicationContext = createContext(initContextProps)

export enum ActionType {
    LoadData = 'LoadData',
    AddEmail = 'AddEmail',
    AddKeyword = 'AddKeyword',
    ShowMessage = 'ShowMessage',
    HideMessage = 'HideMessage',
    SetStatusIsLoading = 'SetStatusIsLoading',
    SetTickets = 'SetTickets',
    ShowEditEmailModal = 'ShowEditEmailModal',
    HideEditEmailModal = 'HideEditEmailModal',
    ShowAddEmailModal = 'ShowAddEmailModal',
    HideAddEmailModal = 'HideAddEmailModal',
    ShowEditKeywordModal = 'ShowEditKeywordModal',
    HideEditKeywordModal = 'HideEditKeywordModal',
    ShowAddKeywordModal = 'ShowAddKeywordModal',
    HideAddKeywordModal = 'HideAddKeywordModal',
    SetEmails = 'SetEmails',
    DeleteEmail = 'DeleteEmail',
    UpdateEmail = 'UpdateEmail',
    SetKeywords = 'SetKeywords',
    DeleteKeyword = 'DeleteKeyword',
    UpdateKeyword = 'UpdateKeyword',
    ResetData = 'ResetData',
    SetAPopupIsOpen = 'SetAPopupIsOpen',
    SetIsLoadingFailure = 'SetIsLoadingFailure',
    SetIsGeneralFailure = 'SetIsGeneralFailure',
}

export interface Action {
    type: ActionType
    payload?: any
}

export const ApplicationReducer: React.Reducer<ApplicationState, Action> = (
    state: ApplicationState,
    action: Action
): ApplicationState => {
    switch (action.type) {
        case ActionType.LoadData:
            return { ...state }
        case ActionType.AddEmail:
            return {
                ...state,
                emails: [action.payload, ...state.emails],
                isLoading: false,
            }
        case ActionType.ShowAddEmailModal:
            return { ...state, showAddEmailModal: true }
        case ActionType.HideAddEmailModal:
            return { ...state, showAddEmailModal: false }
        case ActionType.ShowEditEmailModal:
            return {
                ...state,
                showEditEmailModal: true,
                editEmailId: action.payload,
            }
        case ActionType.HideEditEmailModal:
            return { ...state, showEditEmailModal: false, editEmailId: '' }
        case ActionType.SetEmails:
            return { ...state, emails: action.payload, isLoading: false }
        case ActionType.UpdateEmail:
            return {
                ...state,
                emails: state.emails.map(item =>
                    item.id === action.payload.id
                        ? { ...item, ...action.payload }
                        : item
                ),
            }
        case ActionType.DeleteEmail:
            return {
                ...state,
                emails: state.emails.filter(
                    email => email.id !== action.payload
                ),
                isLoading: false,
            }
        ///////////////////////////
        case ActionType.AddKeyword:
            return {
                ...state,
                keywords: [action.payload, ...state.keywords],
                isLoading: false,
            }
        case ActionType.ShowAddKeywordModal:
            return {
                ...state,
                showAddKeywordModal: true,
                emailId: action.payload.emailId,
            }
        case ActionType.HideAddKeywordModal:
            return { ...state, showAddKeywordModal: false }
        case ActionType.ShowEditKeywordModal:
            return {
                ...state,
                showEditKeywordModal: true,
                editKeywordId: action.payload,
            }
        case ActionType.HideEditKeywordModal:
            return { ...state, showEditKeywordModal: false, editKeywordId: '' }
        case ActionType.ShowMessage:
            return {
                ...state,
                messageContent: action.payload,
                isMessageShown: true,
            }
        case ActionType.SetKeywords:
            return { ...state, keywords: action.payload, isLoading: false }
        case ActionType.UpdateKeyword:
            return {
                ...state,
                keywords: state.keywords.map(item =>
                    item.id === action.payload.id
                        ? { ...item, ...action.payload }
                        : item
                ),
            }
        case ActionType.DeleteKeyword:
            return {
                ...state,
                keywords: state.keywords.filter(
                    keyword => keyword.id !== action.payload
                ),
                isLoading: false,
            }
        case ActionType.HideMessage:
            return { ...state, isMessageShown: false }
        case ActionType.SetTickets:
            return { ...state, tickets: action.payload, isLoading: false }
        case ActionType.SetStatusIsLoading:
            return { ...state, isLoading: action.payload }
        case ActionType.SetAPopupIsOpen:
            return { ...state, aPopupIsOpen: action.payload }
        case ActionType.SetIsGeneralFailure:
            return { ...state, isGeneralFailure: action.payload }
        case ActionType.SetIsLoadingFailure:
            return { ...state, isLoadingFailure: action.payload }
        case ActionType.ResetData:
            return initialState

        default:
            return state
    }
}

const ApplicationContextProvider: React.FC = ({
    children,
}: {
    children?: ReactNode
}) => {
    const [state, dispatch] = useReducer(ApplicationReducer, initialState)
    const showMessage = async (text: string) => {
        dispatch({
            type: ActionType.ShowMessage,
            payload: text,
        })
        await delay(2000)
        dispatch({ type: ActionType.HideMessage })
    }
    return (
        <ApplicationContext.Provider value={{ state, dispatch, showMessage }}>
            {children}
        </ApplicationContext.Provider>
    )
}

const useApplication = () => {
    const context = React.useContext(ApplicationContext)
    if (context === undefined) {
        throw new Error(
            `useApplication must be used within a ApplicationContextProvider`
        )
    }
    return context
}
export { ApplicationContextProvider, useApplication }
