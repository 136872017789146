import React, { ReactNode } from 'react'
import { AuthContextProvider } from './AuthContext'
import { ApplicationContextProvider } from './ApplicationContext'

function AppProviders({ children }: { children?: ReactNode }) {
    return (
        <AuthContextProvider>
            <ApplicationContextProvider>{children}</ApplicationContextProvider>
        </AuthContextProvider>
    )
}

export default AppProviders
