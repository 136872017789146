import React, { createContext, useState } from 'react'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {
    doGetSession,
    doLogin,
    doSavePassword,
    doLogout,
} from './utils/authentication'

interface AuthData {
    authToken: string
    error: boolean
    isLoggedIn: boolean
    newPasswordRequired: boolean
    email?: string
}

interface AuthContextProps {
    data: AuthData
    setData: (data: any) => { error: any; result: any }
    login: (email: string, password: string) => { error: any; result: any }
    logout: () => void
    getSession: () => { error: any; result: any }
    savePassword: (oldPassword: string, newPassword: string) => { error: any }
}

const initData: AuthData = {
    authToken: '',
    error: false,
    isLoggedIn: false,
    newPasswordRequired: false,
}

const initContextProps: AuthContextProps = {
    data: initData,
    login: (_a: string, _b: string) => ({ error: null, result: null }),
    savePassword: (_b: string, _c: string) => ({ error: null }),
    logout: () => true,
    getSession: () => ({ error: null, result: null }),
    setData: (data: any) => ({ error: null, result: null }),
}

const AuthContext = createContext(initContextProps)
const AuthContextProvider = (props: any) => {
    const [data, setData] = useState<AuthData>(initData)

    const getSession = async () => {
        const { result, error } = await doGetSession()
        if (error) {
            setData({ ...data, isLoggedIn: false }) // TODO: set some kind of error that can be displayed to the user
        } else if (result) {
            setData({
                ...data,
                isLoggedIn: true,
                authToken: (result as CognitoUserSession)
                    .getIdToken()
                    .getJwtToken(),
            })
        } else {
            setData({ ...data, isLoggedIn: false }) // TODO: set some kind of error that can be displayed to the user
        }

        return { result, error }
    }

    const login = async (username: string, password: string) => {
        const { result, error } = await doLogin(username, password)
        if (error) {
            setData({ ...data, isLoggedIn: false, error: true })
        } else {
            if (
                Object.prototype.hasOwnProperty.call(
                    result,
                    'email_verified'
                ) &&
                Object.prototype.hasOwnProperty.call(result, 'email')
            ) {
                setData({
                    ...data,
                    newPasswordRequired: true,
                    error: false,
                    email: (result as any).email,
                })
            } else {
                setData({ ...data, isLoggedIn: true, error: false })
            }
        }
        return { result, error }
    }

    const savePassword = async (oldPassword: string, newPassword: string) => {
        const { error } = await doSavePassword(
            data.email || '',
            oldPassword,
            newPassword
        )
        if (error) {
            setData({ ...data, isLoggedIn: false, error: true })
        } else {
            setData({
                ...data,
                isLoggedIn: true,
                error: false,
                newPasswordRequired: false,
            })
        }
        return { error }
    }

    const logout = () => {
        doLogout()
        setData({ ...data, isLoggedIn: false })
    }

    return (
        <AuthContext.Provider
            value={{ data, setData, login, logout, getSession, savePassword }}
            {...props}
        />
    )
}

const useAuth = () => {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthContextProvider`)
    }
    return context
}

export { AuthContextProvider, useAuth }
