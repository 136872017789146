import {
    CognitoUser,
    AuthenticationDetails,
    CognitoUserPool,
} from 'amazon-cognito-identity-js'

const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
}

const userPool = new CognitoUserPool(poolData)

const _getSession = async () =>
    await new Promise((resolve, reject) => {
        const user = userPool.getCurrentUser()
        if (user) {
            user.getSession((err: any, session: any) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(session)
                }
            })
        } else {
            reject()
        }
    })

const doGetSession = async () => {
    let error = null
    let result = null
    try {
        result = await _getSession()
    } catch (e) {
        error = e
    }
    return { result, error }
}

const _login = async (email: string, password: string) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username: email, Pool: userPool })
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                console.log('onSuccess:', data)
                resolve(data)
            },

            onFailure: err => {
                console.error('onFailure:', err)
                reject(err)
            },

            newPasswordRequired: data => {
                console.log('newPasswordRequired:', data)
                resolve(data)
            },
        })
    })

const doLogin = async (email: string, password: string) => {
    let error = null
    let result = null
    try {
        result = await _login(email, password)
    } catch (e) {
        error = e
    }
    return { result, error }
}

const _savePassword = async (
    email: string,
    oldPassword: string,
    newPassword: string
) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username: email, Pool: userPool })
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: oldPassword,
        })

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                resolve(data)
            },

            onFailure: err => {
                reject(err)
            },

            newPasswordRequired: () => {
                user.completeNewPasswordChallenge(
                    newPassword,
                    { email: email },
                    {
                        onSuccess: data => {
                            console.log('onSuccess:', data)
                            return resolve(data)
                        },

                        onFailure: err => {
                            console.error('onFailure:', err)
                            return reject(err)
                        },
                    }
                )
            },
        })
    })

const doSavePassword = async (
    email: string,
    oldPassword: string,
    newPassword: string
) => {
    let error = null
    let result = null
    try {
        result = await _savePassword(email, oldPassword, newPassword)
    } catch (e) {
        error = e
    }
    return { result, error }
}

const _sendVerificationCode = async (email: string) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email.toLowerCase(),
            Pool: userPool,
        })
        if (user) {
            user.forgotPassword({
                onSuccess: data => {
                    console.log('onSuccess:', data)
                    resolve(data)
                },
                onFailure: err => {
                    console.error('onFailure:', err)
                    reject(err)
                },
                inputVerificationCode: data => {
                    console.log('onSuccess:', data)
                    resolve(data)
                },
            })
        } else {
            reject()
        }
    })

const doSendVerificationCode = async (email: string) => {
    let error = null
    let result = null
    try {
        result = await _sendVerificationCode(email)
    } catch (e) {
        error = e
    }
    return { result, error }
}

const _resetPassword = async (code: string, password: string, email: string) =>
    await new Promise<void>((resolve, reject) => {
        const user = new CognitoUser({
            Username: email.toLowerCase(),
            Pool: userPool,
        })
        if (user) {
            user.confirmPassword(code, password, {
                onSuccess: () => {
                    resolve()
                },
                onFailure: err => {
                    console.error('onFailure:', err)
                    reject(err)
                },
            })
        } else {
            reject()
        }
    })

const doResetPassword = async (
    code: string,
    password: string,
    email: string
) => {
    let error = null
    let result = null
    try {
        result = await _resetPassword(code, password, email)
    } catch (e) {
        error = e
    }
    return { result, error }
}

const doLogout = () => {
    const user = userPool.getCurrentUser()
    if (user) {
        user.signOut()
    }
}

export {
    doGetSession,
    doLogin,
    doLogout,
    doSavePassword,
    doSendVerificationCode,
    doResetPassword,
}
